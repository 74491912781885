import { useEffect, useState } from "react";

import { useEntitlements } from "~/features/access";
import { useArticleMeter } from "~/features/paywall/useArticleMeter";
import { usePageData } from "~core/hooks/use-page";
import { useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";
import { pushDataLayer } from "~lib/analytics/datalayer";

import EmailCollectorModal from "./EmailCollectorModal";
import PaywallModal from "./PaywallModal";
import { useOverlay } from "./useOverlay";

const DISABLE_PAYWALL_GLOBAL = import.meta.env.PUBLIC_PAYWALLS === "0";

export default function PaywallFlow({ children, setReadyToInject }) {
  const { article, isPreview } = usePageData();
  const { contentful_id: articleId, paywall_exempt } = article;

  const disablePaywall =
    DISABLE_PAYWALL_GLOBAL ||
    !!paywall_exempt ||
    !!["podcast", "video"].includes(article.media_type);

  const { meterCount, doNotMeter, hasEmailSub } = useArticleMeter(
    articleId,
    disablePaywall,
    isPreview,
  );
  const { changed } = useSciAmJwtSync();
  const { hasDigitalAccess } = useEntitlements();

  const [showEmailCollectorModal, setShowEmailCollectorModal] = useState(false);
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const { setEnabled } = useOverlay();

  useEffect(() => {
    setEnabled(showEmailCollectorModal || showPaywallModal);
    return () => setEnabled(false);
  }, [showEmailCollectorModal, showPaywallModal]);

  useEffect(() => {
    // If we already know there will be no meter/paywall,
    // inform the injector
    if (hasDigitalAccess || paywall_exempt || doNotMeter) {
      setReadyToInject(true);
    }

    // If...
    // - user is a subscriber
    // - content is paywall exempt
    // - there's no meter count yet
    // - user has a pending update to their SciAm JWT that would remove the paywall on their next page load
    // Nothing will be shown
    if (meterCount === undefined || doNotMeter || (changed && hasDigitalAccess)) {
      setShowEmailCollectorModal(false);
      setShowPaywallModal(false);
      return;
    }

    /* Debug tool: Add debug=emailcollector to the URL as a query parameter, and you can force the email collector to appear, but no datalayer happens */
    if (window.location.search.match(/debug=emailcollector/)) {
      setShowEmailCollectorModal(true);
      return;
    }

    /* Debug tool: Add debug=paywall to the URL as a query parameter, and you can force the paywall to appear, but no datalayer happens */
    if (window.location.search.match(/debug=paywall/)) {
      setShowPaywallModal(true);
      return;
    }

    /* If the execution flow reaches this part, we will push relevant datalayer events and values. */

    /* User has THREE pageviews before they see the paywall modal on the FOURTH pageview.  That's why it is THREE minus the meterCount. */
    let viewsLeft = 3 - meterCount;

    // The counter keeps counting, but analytics wants to max out when the user would see a paywall.
    const trackedMeterCount = Math.min(meterCount, 4);

    pushDataLayer({
      event: "meter_updated",
      user: {
        meterName: "hopper",
        meterType: "hoppermeter",
        incremented: !doNotMeter,
        meteredPaywallArticleNum: trackedMeterCount,
        viewsLeft: Math.max(viewsLeft, 0),
      },
    });

    // Pageview 3: users who have not seen the email collector and have no subscription see the email collector
    if (meterCount && meterCount === 3 && !hasEmailSub) {
      pushDataLayer({
        event: "meter_expired",
        user: {
          meteredPaywallArticleNum: trackedMeterCount,
          meterType: "hoppermeter",
        },
        meter: {
          count: trackedMeterCount,
          prompt: "emailcollector",
        },
      });

      setShowEmailCollectorModal(true);
      return;
    }

    // Pageview 4 and beyond: Any users submitted an email on pageview 3 sees the paywall modal.
    // Any users who don't submit an email and or go to another URL after seeing the email collector modal will get a paywall modal.
    if ((meterCount === 3 && hasEmailSub) || meterCount > 3) {
      pushDataLayer({
        event: "meter_expired",
        user: {
          meteredPaywallArticleNum: trackedMeterCount,
          meterType: "hoppermeter",
        },
        meter: {
          count: trackedMeterCount,
          prompt: "paywall",
        },
      });

      setShowPaywallModal(true);
      return;
    }

    setReadyToInject(true);
  }, [meterCount, doNotMeter, hasEmailSub, changed, hasDigitalAccess]);

  return (
    <>
      {children}
      {showEmailCollectorModal && (
        <EmailCollectorModal
          setShowEmailCollectorModal={setShowEmailCollectorModal}
          setReadyToInject={setReadyToInject}
          meterCount={meterCount}
        />
      )}
      {showPaywallModal && <PaywallModal meterCount={meterCount} />}
    </>
  );
}
